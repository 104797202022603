<template>
  <div>
    <!-- 网络用户 -->
    <el-form class="params-wrap" size="small" :inline="true" :model="queryParams">
      <el-form-item label="学号：">
        <el-input v-model="queryParams.account" clearable placeholder="请输入学号"></el-input>
      </el-form-item>
      <el-form-item label="学校：" placeholder="请选择学校">
        <el-select clearable filterable v-model="queryParams.schoolId" @change="getMeals">
          <el-option label="安徽科技学院凤阳校区" :value="1"></el-option>
          <el-option label="蚌埠学院" :value="2"></el-option>
          <el-option label="安徽科技学院龙湖校区" :value="3"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="套餐：" prop="schoolId">
          <el-select clearable filterable v-model="queryParams.mealId" placeholder="请选择套餐">
            <el-option v-for="item in meals" :label="item.name" :value="item.id" :key="item.id"></el-option>
          </el-select>
        </el-form-item>
      <el-form-item>
        <el-button type="success" icon="el-icon-search"  @click="refresh()">搜索</el-button>
      </el-form-item>
       <el-form-item>
          <el-link :href="BaseUrl + `net/admin/userNet/noLogin/export?account=${queryParams.account}&schoolId=${queryParams.schoolId}&mealId=${queryParams.mealId}`" class="button_download">
            <el-button type="warning" size="small" icon="el-icon-position">导出</el-button>
          </el-link>
        </el-form-item>
    </el-form>
    
    <div class="content-wrap">
        <!--列表-->
        <paged-table
          :data="netUserList"
          :total="netUserTotal"
          :loading="tLoading"
          :isShowSelection="false"
          defaultSortProp="id"
          v-model="queryParams"
          @refresh="refresh">
          >
          <el-table-column
            prop="extend.schoolName"
            label="学校"
            :show-overflow-tooltip="true"
          >
            <!-- <template slot-scope="scope">
              <span v-if="scope.row.sex==1">男</span>
              <span v-if="scope.row.sex==2">女</span>
            </template>             -->
          </el-table-column>
          <el-table-column
            prop="name"
            label="姓名"
            :show-overflow-tooltip="true"
          >
          </el-table-column>
          <el-table-column
            prop="account"
            label="学号"
            :show-overflow-tooltip="true"
          >
          </el-table-column>
          <el-table-column
            prop="typeName"
            label="类型"
            :show-overflow-tooltip="true"
          >
          </el-table-column>
          <el-table-column
            prop="mealMobile"
            label="融合号码"
            :show-overflow-tooltip="true"
          >
          </el-table-column>
          <el-table-column
            prop="mealName"
            label="套餐名称"
            :show-overflow-tooltip="true"
          >
          </el-table-column>
          <el-table-column
            prop="startDate"
            label="套餐开始时间"
            :show-overflow-tooltip="true"
          >
          </el-table-column>   
          <el-table-column
            prop="endDate"
            label="套餐结束时间"
            :show-overflow-tooltip="true"
          >
          </el-table-column>
          <el-table-column prop="remark" show-overflow-tooltip label="备注"></el-table-column>            
        </paged-table>
      </div>
  </div>
</template>

<script>
import { BaseUrl } from "@/common/config";
import { mapActions, mapState, mapMutations } from "vuex";
import PagedTable from "@/components/common/PagedTable";
// import BaseImport from "@/components/common/BaseImport";
// import AreaTree from "@/components/common/AreaTree";
import pageMixin from "@/mixins/pageMixin";
import { log, mergeObject } from "@/common/utils";
export default {
  name: "netUser",
  components: {    
    PagedTable,
    // AreaTree,
    // BaseImport
  },
  mixins: [pageMixin],
  data() {
    return {
      BaseUrl,
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        account: '',
        schoolId: '',
        mealId: '',
      },
      meals: [],      
           
      pageApi: "userNetList",
    };
  },
  computed: {
    ...mapState(["OA"]),
    ...mapState("netUser", ["netUserList", "netUserTotal"]),
  },
  methods: {
    ...mapActions("netUser",["userNetList","mealList"]),

    async getMeals(){
      this.queryParams.mealId = ''
      let schoolId = this.queryParams.schoolId
      console.log("schoolId",schoolId)
      try{
        const res = await this.mealList({schoolId})
        this.meals = res
      }catch(e){
        log(e)
      }
    }   
  },
  async mounted() {
    if(this.getPageParams(this.$options.name))
    mergeObject(this.queryParams, this.getPageParams(this.$options.name))
    this.refresh()

    this.getMeals()
  },
};
</script>
